import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Sohne";
    src: url("/Sohne-Buch.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sohne";
    src: url("/Sohne-Extraleicht.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sohne";
    src: url("/Sohne-Fett.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sohne";
    src: url("/Sohne-Halbfett.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sohne";
    src: url("/Sohne-Kraftig.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sohne";
    src: url("/Sohne-Leicht.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  body {
    font-family: 'Inter', sans-serif;
    background-color: #121212; /* Dark background color */
    color: #eeeeee; /* Light text color */
  }

  * {
    font-family: inherit;
  }

  html, body, #__next {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #0a0a0c 0%, #1a1a1c 100%);
    overscroll-behavior: none;
    -webkit-font-smoothing: subpixel-antialiased !important;
  }

  #__next {
    display: flex;
    flex-direction: column;
  }

  * {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      scroll-behavior: smooth !important;
      -webkit-tap-highlight-color: transparent;
      -webkit-font-smoothing: never;
  }

  .hide {
    display: none;
  }

  svg {
    vertical-align: text-top;
  }

  a {
    color: unset;
    text-decoration: none;
  }

  button {
    border: none;
    outline: none;
    background: transparent;
    width: fit-content;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Sohne", sans-serif;
    font-weight: 500;
    color: #eeeeee; /* Light text color */
  }
`;

export default GlobalStyle;
