import { MantineProvider, createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/code-highlight/styles.css";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { DM_Sans } from "next/font/google";
import Head from "next/head";
import React from "react";
import GlobalStyle from "src/constants/globalStyle";
import { Loading } from "src/layout/Loading";
import { supabase } from "src/lib/api/supabase";
import useUser from "src/store/useUser";
import "../styles/globals.css";

const dmSans = DM_Sans({
  subsets: ["latin-ext"],
});

const theme = createTheme({
  autoContrast: true,
  fontSmoothing: false,
  respectReducedMotion: true,
  cursorType: "pointer",
  fontFamily: dmSans.style.fontFamily,
  defaultGradient: {
    from: "#000000",
    to: "#1A1A1A",
    deg: 180,
  },
  primaryShade: 8,
  radius: {
    lg: "12px",
  },
  components: {
    Button: {
      defaultProps: {
        fw: 500,
      },
    },
  },
});

const Toaster = dynamic(() => import("react-hot-toast").then((c) => c.Toaster));

function JsonCrack({ Component, pageProps }: AppProps) {
  const setSession = useUser((state) => state.setSession);

  React.useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) setSession(session);
    });
  }, [setSession]);

  return (
    <>
      <Head>
        <title>Nerval ML | Data pipelines built for the future.</title>
      </Head>
      <Toaster
        position="bottom-right"
        containerStyle={{
          bottom: 34,
          right: 8,
          fontSize: 14,
        }}
        toastOptions={{
          style: {
            background: "#4D4D4D",
            color: "#B9BBBE",
            borderRadius: 4,
          },
        }}
      />
      <GlobalStyle />
      <MantineProvider defaultColorScheme="dark" theme={theme}>
        <Loading />
        <Component {...pageProps} />
      </MantineProvider>
    </>
  );
}

export default JsonCrack;
